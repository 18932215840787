/* eslint-disable no-unused-vars */
import React from 'react'

import DefaultPage from '../../UI/DefaultPage/DefaultPage'

import classes from './admin.module.css'
import widgetClasses from '../../builders/widget.module.css'

export default function Admin () {
    return (
        <DefaultPage headerText="Admin Panel"></DefaultPage>
    )
}
