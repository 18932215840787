import React from 'react'

import BodyHeader from '../../UI/BodyHeader/BodyHeader'

// eslint-disable-next-line no-unused-vars
import classes from './friendSettings.module.css'

export default function FriendSuggestions () {
    return (
        <BodyHeader>Suggested People</BodyHeader>
    )
}
