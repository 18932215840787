import React from 'react'
import DefaultPage from '../../UI/DefaultPage/DefaultPage'

export default function groupSettings () {
    return (
        <DefaultPage headerText="Group Setup">

        </DefaultPage>
    )
}
